export interface ServiceProps { children: string, }
export const HomeService: React.FC<ServiceProps> = ({ children }) => {
    return (
        <div>
            <span
                style={{
                    color: "rgb(44, 171, 234)",
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginRight: "5px"
                }}>
                {'>'}
            </span>
            <span
                style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "rgb(85, 85, 85)"
                }}
            >
                {children}
            </span>
        </div>
    )
}
