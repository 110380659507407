import React from 'react'
import SocialLinks from '../SocialLinks'

export const topBorder: React.CSSProperties = {
    borderTop: "1px solid rgb(44, 171, 234)",
    paddingTop: "20px"
}

const contentBox: React.CSSProperties = {
    ...topBorder,
    fontSize: "18px",
    boxShadow: "0px 13px 0px -5px rgba(191,193,193,0.6)",
    marginBottom: "20px"
}

const About = () => {
    return (
        <>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-4 text-start' >
                        <h1 className=''>About</h1>
                        <div style={topBorder}>
                            <img src="/profile.webp" alt="" />
                        </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-8'>
                        <h1 style={{ visibility: "hidden" }}>Space</h1>
                        <div className='text-start' style={contentBox}>
                            <div style={{ backgroundColor: "rgb(248, 249, 251)", padding: "20px", marginTop: "20px", color: "rgb(85, 85, 85)" }}>
                                <p>
                                    "I moved to Canada as an International Student in 2010. Being an immigrant myself, I understand how many challenges one has to face to move and settle in a new country, how hard it can be to unite families, how difficult it can be to bring family members to come visit you and also to go visit families and friends in other countries like the USA and many others. Therefore, I've made it my life's purpose to assist everyone I can in simplifying their lives with the knowledge I have and will hopefully gain in my journey."
                                    <p className='text-end'><b>- Navjot Singh Multani</b></p>
                                </p>
                                <p>
                                    Navjot Singh Multani is a Regulated Canadian Immigration Consultant
                                    ( RCIC # - R708700). He graduated from Simon Fraser University with Bachelor of Business Administration (Finance) and finished his Immigration Consultant Diploma with honors.
                                </p>



                                <h2 style={{ textDecoration: "underline" }}>Our Vision</h2>

                                Our motive is to make your experience smooth and stress free.
                                When it comes to fulfilling your immigration needs, we use:
                                <ul>
                                    <li>Simple communication</li>
                                    <li>Proper guidance</li>
                                    <li>Efficient work</li>
                                    <li>Ethical practices</li>
                                </ul>

                                We look forward to serving you.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About