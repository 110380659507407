import React from 'react'
import SocialLinks from './SocialLinks'

const linksStyle: React.CSSProperties = {
    fontSize: "20px",
    color: "rgb(213, 214, 217)"
}

const borderStyle: React.CSSProperties = {
    borderLeft: "1px solid"
}



const Footer = () => {
    return (
        <div style={{ backgroundColor: "rgb(85, 85, 85)" }}>
            <div className='container' >
                <div className='row' style={{ padding: "20px 0" }}>
                    <div className='col-12 col-sm-12 col-lg-4  gy-3'>
                        <div><img src="/rcic.webp" alt="rcic logo" /></div>
                        <div><img src="/cicc.webp" alt="cicc logo" /></div>
                    </div>
                    <div className='col-12 col-sm-12 col-lg-4  gy-3' style={borderStyle}>
                        <span style={linksStyle}>Navjot Singh Multani</span> <br />
                        <a style={linksStyle} href="mailto:info@nsmimmigration.com">info@nsmimmigration.com</a> <br />
                        <span style={linksStyle}>Tel :</span><a style={linksStyle} href="tel:1778-839-1019">+1 (778) 839 1019</a>
                    </div>
                    <div className='col-12 col-sm-12 col-lg-4  gy-3' style={borderStyle}>
                        <div style={linksStyle}>Connect Online</div>
                        <SocialLinks />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer