import React from 'react'


const style: React.CSSProperties = {
    boxShadow: "0px 13px 0px -5px rgba(191,193,193,0.6)",
    maxWidth: "291px",
    height: "470px",
    backgroundColor: "rgb(248, 249, 251)",
    margin: "25px auto"
}

export interface ServiceProp {
    title: string,
    points: string[],
    img: string
}
const Service: React.FC<ServiceProp> = ({ title, points, img }) => {
    return (
        <div style={style}>
            <img src={img} alt="" style={{ width: "291px", height: "255px" }} />
            <h1 style={{ fontSize: "24px", backgroundColor: "rgb(44, 171, 234)", color: "white", marginTop: '20px', padding: "4px" }}>{title}</h1>
            <div>
                <ul style={{ fontSize: "20px", textAlign: "left", color: "rgb(85, 85, 85)" }}>
                    {points.map((p) => <li key={p}>{p}</li>)}
                </ul>
            </div>
        </div>
    )
}

export default Service