import React from 'react'

const socialStyle: React.CSSProperties = {
    marginRight: "10px"
}

const SocialLinks = () => {
    return (
        <>
            <a style={socialStyle} href="https://www.instagram.com/nsm_immigration/"><img src="insta.webp" alt="instagram logo" /></a>
            <a style={socialStyle} href="https://www.facebook.com/NSM-Immigration-Services-107168118676516/"><img src="fb.webp" alt="facebook logo" /></a>
            <a style={socialStyle} href="https://api.whatsapp.com/send?phone=17788391019"><img src="whatsapp.webp" alt="whatsapp logo" /></a>
        </>
    )
}

export default SocialLinks