import React from 'react'
import { NavLink } from 'react-router-dom'
import HomeFeature from './HomeFeature'
import { HomeService } from './HomeService'

const flagStyle = {
    width: "40px",
    height: "20px",
    marginRight: "10px"
}

const servicesStyle: React.CSSProperties = {
    fontWeight: "bold",
    fontSize: "18px",
    color: "rgb(85, 85, 85)"

}

const Home = () => {
    return (
        <>
            <img style={{ width: "100%", height: "600px", objectFit: "cover" }} src="/science-world.jpg" alt='science world' role="presentation"></img>
            <div className='container'>
                <div id="home-container" className="row">
                    <div id="main-description" className="col-12 col-sm-12 col-md-8 text-start gy-5">
                        Over the years, migration in Canada has been on the rise because of the undeniable growth in education and employment opportunities.
                        If settling in Canada is your dream, we can help you understand what you need to know about the entire immigration process while doing everything the best possible way. We offer comprehensive immigration services tailored to focus on your individual needs.
                    </div>
                    <div className="col-12 col-md-4 text-start gy-5">
                        <span style={servicesStyle}>Offering Services For</span>
                        <div>
                            <img style={flagStyle} src='canada.png' alt='canada flag' />
                            <img style={flagStyle} src='india.png' alt='india flag' />
                            <img style={flagStyle} src='usa.png' alt='usa flag' />
                            <img style={flagStyle} src='uk.png' alt='uk flag' />
                            <img style={flagStyle} src='australia.png' alt='australia flag' />
                        </div>
                        <hr style={{ borderTop: "1px solid" }}></hr>
                        <div style={{ marginTop: "20px" }}>
                            <HomeService>Permanent Residence</HomeService>
                            <HomeService>Family Sponsorship</HomeService>
                            <HomeService>Citizenship Applications</HomeService>
                            <HomeService>Student Visas</HomeService>
                            <HomeService>Work Permits</HomeService>
                        </div>
                        <br />
                        <NavLink to="/services" style={{ textDecoration: "none", fontWeight: "bold", fontSize: "18px", color: "rgb(44, 171, 234)" }}>Read More</NavLink>
                    </div>
                </div>
                <div className='text-start row' style={{ marginTop: "50px" }}>
                    <div className='col-md-4'>
                        <HomeFeature title='Immigrate' description='Canada embraces multi-culturalism & immigrants' img='/immigrate.webp'></HomeFeature>
                    </div>
                    <div className='col-md-4'>
                        <HomeFeature title='Study' description='Study in Canada for a promising future' img='/study.webp'></HomeFeature>
                    </div>
                    <div className='col-md-4'>
                        <HomeFeature title='Travel' description='Canada has spectacular vacation spots' img='/travel.webp'></HomeFeature>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home