import React from 'react'
import { topBorder } from './About/About'
import SocialLinks from './SocialLinks'

const Contact = () => {
    return (
        <div className='container'>
            <h1>Contact</h1>
            <div style={{ ...topBorder, fontSize: "20px;", fontWeight: "bold", marginBottom: "20px" }} className="text-start">
                <p>Navjot Singh Multani</p>
                <p>RCIC # R708700</p>
                <p><a href="mailto:info@nsmimmigration.com">info@nsmimmigration.com</a></p>
                <p><a href="http://www.nsmimmigration.com">www.nsmimmigration.com</a></p>
                Tel: <a href="tel:1778-839-1019">+1 (778) 839 1019</a> <br /><br />
                <SocialLinks />
            </div>
        </div >

    )
}

export default Contact