import React from 'react'
import Service from './Service'

export const cols = "col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3 justify-content-center"

const Services = () => {
    return (
        <div className="container">
            <h1>Services</h1>
            <div style={{ borderTop: "1px solid rgb(44, 171, 234)" }} className="row">
                <div className={cols}>
                    <Service
                        title='Permanent Residence'
                        img='/prcard.webp'
                        points={["Express Entry", "PNP", "PR Renewal"]}
                    />
                </div>
                <div className={cols}>
                    <Service
                        title='Family Sponsorship'
                        img='/reunification.webp'
                        points={["Spousal PR", "Spousal Open Work Permit", "Parents & Grandparents PR", "Super Visa / Visitor Visa"]}
                    />
                </div>
                <div className={cols}>
                    <Service
                        title='International Students'
                        img='/students.webp'
                        points={["Study Visa / Visitor Visa", "Study Permit Extension", "Post Graduation Work Permit"]}
                    />
                </div>
                <div className={cols}>
                    <Service
                        title='Citizenship'
                        img='/citizenship.webp'
                        points={["Citizenship Application"]}
                    />
                </div>
                <div className={cols}>
                    <Service
                        title='Work'
                        img='/work.webp'
                        points={["LMIA", "Business Visitors", "Open Work Permit"]}
                    />
                </div>
                <div className={cols}>
                    <Service
                        title='Extension Of Status'
                        img='/extension.webp'
                        points={["Visitor Visa Extension", "Study Permit Extension", "Work Permit Extension"]}
                    />
                </div>
                <div className={cols}>
                    <Service
                        title='International Services'
                        img='/international.webp'
                        points={["Canada, USA, UK, Australia, Indian, Schengen Visitor Visa", "Indian Passport Surrender", "OCI Application"]}
                    />
                </div>
            </div>
        </div>
    )
}

export default Services