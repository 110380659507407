import React from 'react'

export interface HomeFeatureProps {
    title: string;
    description: string;
    img: string;
}
const HomeFeature: React.FC<HomeFeatureProps> = ({ title, description, img }) => {
    return (
        <div style={{ width: "100%", maxWidth: "246px", margin: "auto" }}>
            <img style={{ width: "100%", maxWidth: "246px", height: "246px" }} src={img} alt=""></img>
            <h3 style={{ backgroundColor: "rgb(44, 171, 234)", marginTop: "20px", color: "white" }} className="text-center">{title}</h3>
            <div style={{ fontSize: "19px" }} className="text-center">{description}</div>
            <hr style={{ marginTop: "50px", borderTop: "7px solid" }}></hr>
        </div>
    )
}

export default HomeFeature