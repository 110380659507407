import React from 'react'
import { Link, NavLink } from 'react-router-dom'

const nameStyle = {
    display: "block",
    color: "rgb(249, 218, 116)",
    "text-align": "left",
}

const personName = {
    ...nameStyle,
    fontSize: "18px"
}

const bName = {
    ...nameStyle,
    fontSize: "13px"
}

const getNavClass = (isActive: boolean): string => {
    return isActive ? "nav-link active" : "nav-link";
}

const AppHeader = () => {
    return (
        <nav className="navbar navbar-expand-sm" style={{ backgroundColor: '#2b548f' }}>
            <div className='container'>
                <NavLink to="/" className="navbar-brand" style={{ display: "flex" }}>
                    <img src="/logo.png" alt="" width="30" height="24" style={{ width: "auto", height: "85px" }} />
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", paddingTop: "10px", marginLeft: "10px" }}>
                        <span style={personName}>Navjot Multani</span>
                        <span style={bName}> NSM Immigration Services</span>
                    </div>
                </NavLink>
                <button style={{ backgroundColor: "white" }} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <NavLink to="/" className={({ isActive }) => getNavClass(isActive)}>Home</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/about" className={({ isActive }) => getNavClass(isActive)}>About</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/services" className={({ isActive }) => getNavClass(isActive)}>Services</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/contact" className={({ isActive }) => getNavClass(isActive)}>Contact</NavLink>
                        </li>
                    </ul>
                </div>

            </div >
        </nav >
    )
}

export default AppHeader